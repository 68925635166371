import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import {
  Intro,
  Benefits,
  Translators,
  Packages,
  SliderSeo,
} from "page_components/offer"

const Offer = ({ pageContext }) => {
  const data = pageContext?.data?.data?.acfOffer
  const lang = pageContext.data.data.language.slug

  return (
    <Layout
      seo={{ title: pageContext?.data?.title }}
      lang={lang}
      translations={pageContext.data.translations}
    >
      <PageHeader title={pageContext?.data?.title} />
      <Intro data={data} />
      <Benefits data={data} />
      <Translators data={data} />
      <Packages data={data} />
      {data?.sliderContent && <SliderSeo data={data} />}
    </Layout>
  )
}

export default Offer
