import "./styles.scss"

import React from "react"

const Intro = ({ data }) => {
  return (
    <section className="intro-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 text-center">
            <img src={data?.introImage?.localFile?.publicURL} alt="" />
          </div>
          <div className="col-md-5 offset-md-1">
            <div dangerouslySetInnerHTML={{ __html: data?.introContent }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
