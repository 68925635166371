import "./styles.scss"

import React from "react"

const Translators = ({ data }) => {
  return (
    <section className="translators-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <div
              dangerouslySetInnerHTML={{ __html: data?.translatorsContent }}
            />
          </div>
          <div className="col-md-5 text-center">
            <img src={data?.translatorsImage?.localFile?.publicURL} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Translators
