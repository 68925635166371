import "./styles.scss"

import React from "react"

const Packages = ({ data }) => {
  return (
    <section className="packages-main">
      <div className="container-fluid">
        <h2 dangerouslySetInnerHTML={{ __html: data?.packagesHeader }} />
        <div className="row justify-content-center">
          {data?.packagesContent?.map((item, index) => (
            <div className="col-lg-5" key={index}>
              <div className="packages-main__item">
                <h4>{item?.header}</h4>
                <div className="packages-main__item-row">
                  {item?.row?.map((col, index) => (
                    <>
                      <div className="packages-main__item-col">
                        <div className="packages-main__item-icon">
                          <img
                            src={col?.icon?.localFile?.publicURL}
                            alt={col.description}
                          />
                        </div>
                        <p>{col.description}</p>
                      </div>
                      <div className="packages-main__item-col">
                        <span>+</span>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Packages
