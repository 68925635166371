import "./styles.scss"

import React from "react"

const Benefits = ({ data }) => {
  return (
    <section className="benefits-main">
      <div className="container-fluid">
        <h2>{data?.benefitsHeader}</h2>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row">
              {data?.benefitsContent?.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="benefits-main__item">
                    <img
                      src={item.icon.localFile.publicURL}
                      alt={item.header}
                    />
                    <p>{item.header}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits
