import "./styles.scss"

import React from "react"
import Slider from "react-slick"

const SliderSeo = ({ data }) => {
  const offerSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    pauseOnHover: true,
    adaptiveHeight: true,
  }

  return (
    <section className="slider-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 offset-md-1">
            <Slider {...offerSlider}>
              {data?.sliderContent?.map((item, index) => (
                <div key={index}>
                  <h4>{item?.header}</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.leftContent }}
                  />
                </div>
              ))}
            </Slider>
          </div>
          {data?.rightContent && (
            <div className="col-md-3 offset-md-1">
              <span className="slider-main__space" />
              <p>{data.rightContent}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default SliderSeo
